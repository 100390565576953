.SearchMobile{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100vh;
    padding: 32px 0;
    margin: 0 auto;
    transform: scale(0);
    background-color: #000000c8;
    transition: all .3s ease;
    &.activeMobMnu{
        transform: scale(1);
        
    }
    .close{
        position: absolute;
        top: 24px;
        right: 24px;
    }
    
    input{
        display: block;
        width: 85%;
        background-color: transparent;
        color: #c3c3c3;
        border: none;
        outline: none;        
        padding: 0;
        font-size: 16px;
        padding-left: 28px;
        padding-top: 16px;
        padding-bottom: 16px;
        margin: 84px auto 24px;
        border: 1px solid #888888;
        border-radius: 20px;
    }
    ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        border-radius: 14px;
        width: 100%;
        height: 100%;
        
    }
    li{
        width: 85%;
        margin: 2px auto;
        background-color: #0a5447;
        padding: 8px;
        border-radius: 14px ;
        text-align: left;
        &:hover{
            background-color: #0b715e;
            cursor: pointer;
        }
        a{
            
            color: #b9b9b9;
            
        }
        
    }
}

@media(max-width: 840px){
    
 .mainNav{
    display: none;
 }
    
}