.filter_item{
    white-space: nowrap;
    border: 1px solid #004E41;
    background-color: #131313;
    padding: 8px 16px;
    color: #c3c3c3;
    border-radius: 24px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: all .4s ease;
    margin-right: 12px;
    margin-bottom: 12px;
    &:hover{
        opacity: 0.7;
    }
}
.filter_item.isActive{
    background-color: #004E41;
}