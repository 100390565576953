.cart_item{
    padding-bottom: 24px;
    border-bottom: 1px solid #F9FFFE;
    margin-bottom: 32px;
    padding-right: 10px;
    .cart_itemTop{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 24px;
        .cart_itemImg{
            width: 116px;
            margin-right: 24px;
            border-radius: 4px;
        }
        .cart_itemData{
            display: flex;
            flex-wrap: wrap;
            p{
                margin-top: 0;
                width: 100%;
                text-align: left;
                font-size: 20px;
                font-weight: 400;
                line-height: 22px; /* 22.8px */
                letter-spacing: -0.4px;
            }
            .cart_itemData_box{
                margin-right: 40px;
                text-align: left;
                font-size: 16px;
                color: #c3c3c3;
                span{
                    display: block;
                    margin-top: 8px;
                    font-size: 18px;
                    color: #fff;
                }
            }
            
        }
        .cart_itemData_delete{
            background-color: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            &:hover{
                opacity: 0.6;
            }
        }
        
    }
    .cart_item_bottom{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .cart_item_qty{
            font-size: 20px;
            button{
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                color: #fff;
                font-size: 20px;
            }
            span{
                display: inline-block;
                margin: 0 27px;
            }
        }
        .cart_item_price{
            text-align: right;
            font-size: 20px;
            .cart_item_price_old{
                color: #c3c3c3;
                margin-right: 16px;
                text-decoration: line-through;
            }
        }
    }
}

@media (max-width: 567px){
    .cart_item{
        padding-bottom: 12px;
        margin-bottom: 32px;
        padding-right: 4px;
        .cart_itemTop{
            display: flex;
            align-items: flex-start;
            margin-bottom: 24px;
            .cart_itemImg{
                width: 90px;
                margin-right: 24px;
            }
            .cart_itemData{
                display: flex;
                flex-wrap: wrap;
                p{
                    font-size: 18px;
                }
                .cart_itemData_box{
                    margin-right: 33px;
                    font-size: 14px;
                    color: #c3c3c3;
                    span{
                        display: block;
                        margin-top: 8px;
                        font-size: 16px;
                    }
                }
                
            }
           
            
        }
        .cart_item_bottom{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .cart_item_qty{
                font-size: 16px;
                button{
                    background-color: transparent;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    color: #fff;
                    font-size: 16px;
                }
                span{
                    display: inline-block;
                    margin: 0 22px;
                }
            }
            .cart_item_price{
                text-align: right;
                font-size: 16px;
                .cart_item_price_old{
                    color: #c3c3c3;
                    margin-right: 16px;
                    text-decoration: line-through;
                }
            }
        }
    }
}