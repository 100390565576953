.policy_wrapper{
    max-width: 857px;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    h1{
        color:  #F9FFFE;
        text-align: center;
        font-family: "BlackProText";
        font-size: 48px;
        font-weight: 200;
        letter-spacing: -0.96px;
        text-transform: uppercase;
        text-align: left;
    }
    .policy_wrapper{
        color: #F9FFFE;
        font-size: 20px;
        line-height: 140%; /* 28px */
        letter-spacing: -0.4px;
        margin-bottom: 3px;
        &:last-child{
            margin-bottom: 64px;
        }
    }
    .policy_wrapper_bottom{
        margin-top: 32px;
        font-size: 20px;
        line-height: 28px;
        color: #F9FFFE;
        a{
            color: #004E41;
        }
    }
    .policy_wrapperLast{
        color: #F9FFFE;
        font-size: 20px;
        line-height: 140%; /* 28px */
        letter-spacing: -0.4px;
        margin-bottom: 64px;
        &:last-child{
            margin-bottom: 64px;
        }
    }
    .policy_item{
        position: relative;
        padding: 40px 32px;
        border-top: 1px solid #595959;
        border-bottom: 1px solid #595959;
        padding-left: 106px;
        &_num{
            position: absolute;
            width: 50px;
            top: 23px;
            left: 32px;
            color: #F9FFFE;
            font-size: 40px;
            font-weight: 200;
            font-family: "BlackProText";
            text-align: center;
            img{
                position: absolute;left: 0;top: 6px;
            }
        }
        &_title{
            font-size: 20px;
            margin-bottom: 24px;
            color: #F9FFFE;
            margin-top: 0;
        }
        &_text{
            p{
               font-size: 16px;
               color: #C3C3C3;
               line-height: 24px; 
               margin-bottom: 12px;
            }
            ol{
                padding-left: 20px;
                li{
                    font-size: 16px;
                    color: #C3C3C3;
                    line-height: 24px;
                }
            }
        }
    }
}



@media (max-width: 992px){
    .policy_wrapper{
        h1{
            
            font-size: 36px;
            padding: 0 20px;
        }
        .policy_wrapper{
            font-size: 18px;
            padding: 0 20px;
            
        }
        .policy_wrapperLast{
            color: #F9FFFE;
            font-size: 18px;
            padding: 0 20px;
            
        }
        .policy_wrapper_bottom{
            padding: 0 20px;
            font-size: 16px;
        }
        .policy_item{
            padding: 40px 32px;
            border-top: 1px solid #595959;
            border-bottom: 1px solid #595959;
            &_num{
               font-size: 36px; 
            }
            &_title{
                font-size: 20px;
                margin-bottom: 24px;
                color: #F9FFFE;
                padding-left: 60px;
            }
            &_text{
                p{
                   font-size: 16px;
                   color: #C3C3C3;
                   line-height: 24px; 
                   margin-bottom: 12px;
                }
                ol{
                    padding-left: 20px;
                    li{
                        font-size: 16px;
                        color: #C3C3C3;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}