.adressItem{
    text-align: left;
    padding: 32px;
    border-radius: 4px;
    border: 1px solid #004E41;
    max-width: 432px;
    width: 100%;
    margin-bottom: 20px;
    .topRow{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #F9FFFE;
        margin-bottom: 24px;
        span{
            display: flex;
            align-items: center;
            font-size: 20px;
        }
        img{
            margin-right: 12px;
        }
        button{
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;
            img{
                margin: 0;
            }
        }
    }
    .adressRow{
        margin-bottom: 24px;
        p, span{
            width: 100%;
            display: block;
            margin: 0;
        }
        p{
            font-size: 16px;
            margin-bottom: 8px;
            color: #C3C3C3;
        }
        span{
            color: #F9FFFE;
        }
    }
    .adressRowFlex{
        display: flex;
        margin-bottom: 24px;
        &:last-child{
            margin-bottom: 0;
        }
        div{
            margin-right: 32px;
            span{
                display: block;
                line-height: 24px;
                color: #C3C3C3;
                margin-bottom: 8px;
                &:last-child{
                    color: #F9FFFE;
                    margin-bottom: 0;
                }
            }
        }
     }


  
}

@media(max-width: 768px){
    .adressItem{
        width: 92%;
    }
}