.loginWrapper{
    display: flex;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 64px 0;
    position: relative;
    .absImg{
        position: absolute;
        bottom: 64px;
        right: 50%;
    }
    .loginWrapper_left{
        width: 50%;
        p{
            font-size: 40px;
            color: #c3c3c3;
            font-family: "BlackProText";
            font-weight: 200;
            text-transform: uppercase;
            margin: 0;
            line-height: 64px;
            text-align: left;
            &:last-child{
                font-family: 'BlackProDisplay';
                font-style: italic;
                font-weight: 500;
            }
        }
    }
    .loginWrapper_right{
        width: 50%;
        form{
            width: 100%;
            background-color: #C3C3C3;
            padding: 40px 92px;
            text-align: left;
            h1{
                font-family: 'BlackProDisplay';
                font-style: italic;
                font-weight: 500;
                color: #131313;
                font-size: 52px;
                text-transform: uppercase;
                margin-bottom: 40px;
            }
            input{
                color: #1c1c1c;
                margin-bottom: 40px;
                width: 100%;
                font-size: 16px;
                line-height: 18px;
                background-color: transparent;
                border: none;
                outline: none;
                padding-bottom: 13px;
                border-bottom: 1px solid #131313;
                &::placeholder{
                    color: #1c1c1c;
                    font-size: 16px;
                }
            }
            .passwordBlock{
                position: relative;
                .showPass{
                    position: absolute;
                    top: 0;
                    right: 0;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    cursor: pointer;
                }
            }
            .formCheck{
                margin-bottom: 48px;
                color: #131313;
                font-size: 16px;
                height: 16px;
                line-height: 16px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .remember{
                    padding: 0;
                    margin-right: 10px;
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    text-align: center;
                    border: 1px solid #131313;
                    background-color: transparent;
                    cursor: pointer;
                }
            }
            .accBtn{
                background-color: #004E41;
                padding: 16px;
                font-size: 20px;
                text-align: center;
                color: #f9fffe;
                width: 100%;
                border: none;
                outline: none;
                border-radius: 4px;
                margin-bottom: 32px;
                cursor: pointer;
            }
            .ask{
                color: #131313;
                margin-bottom: 8px;
                a{
                    font-weight: 700;
                    color: #004E41;
                }
            }
        }
    }
}
@media(max-width: 768px){
    .loginWrapper{
        display: flex;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        padding: 0;
        position: relative;
        .absImg{
            display: none;
        }
        .loginWrapper_left{
            display: none;
            
        }
        .loginWrapper_right{
            width: 100%;
        }
        form{
            padding: 72px 20px 56px!important;
        }
    }
} 