.adressWrap{
    text-align: left;
    
    h2{
        width: 100%;
        font-family: 'BlackProText';
        font-size: 48px;
        color: #F9FFFE;
        font-weight: 200;
        letter-spacing: -0.96px;
        text-transform: uppercase;
        margin-top: 0;
        text-align: left;
    }
    p{
        width: 100%;
        text-align: left;
        font-size: 32px;
        color: #F9FFFE;
    }
    .adressRow{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .addItem{
        text-align: left;
        padding: 32px;
        border-radius: 4px;
        border: 1px solid #004E41;
        max-width: 432px;
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #C3C3C3;
        font-size: 18px;
        cursor: pointer;
        &:hover{
            opacity: 0.6;
        }
        img{
            margin-right: 12px;
        }
    }
}

@media(max-width: 768px){
    .adressWrap{
      .addItem{
        width: 92%;
    }  
    }
    
}