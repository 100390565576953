.productWrapp {
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  padding-top: 24px;
  padding-bottom: 75px;
  .breadcrumbs {
    background-color: #fff;
  }
  .productSubTitle {
    //margin-top: 40px;
    color: #c3c3c3;
    font-size: 14px;
    letter-spacing: -0.28px;
    text-align: left;
  }
  .productData_descr {
    margin-top: 40px;
  }
  .SliderWrap {
    flex: 1;
    width: 200px;
    position: relative;
    // margin-left: 108px; // setting a big photo slide width by padding left
  }
  .oldPrice {
    text-decoration: line-through;
    color: #004e41;
    font-size: 22px;
    margin-left: 14px;
  }
  .productData {
    max-width: 488px;
    margin-left: 20px;
    width: 100%;
    .productData_top {
      background-color: #1c1c1c;
      text-align: left;
      font-size: 14px;
      padding: 16px;
      .productData_name {
        font-size: 20px;
        line-height: 22.8px;
        letter-spacing: -0.4px;
        margin-bottom: 16px;
      }
      .productData_sku {
        color: #828282;
        font-size: 18px;
        margin-bottom: 16px;
      }
      .productData_price {
        color: #f9fffe;
        font-size: 32px;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.64px;
        margin-bottom: 30px;
      }
      .productData_item {
        font-size: 14px;
        color: #f9fffe;
        margin-bottom: 24px;
      }
      .watchYoutube {
        margin-left: 36px;
        margin-top: 30px;
      }
      .productData_attrs {
        .productAttrSubTitle {
          font-family: 'Inter';
          font-weight: 500;
          color: #b9bbbf;
          font-size: 16px;
        }
        .productColor {
          padding: 0;
          border: none;
          background-color: transparent;
          cursor: pointer;
          transition: all 0.3s ease;
          margin-right: 16px;
          margin-bottom: 16px;
          width: 48px;
          height: 46px;
          border-radius: 4px;
          border: 2px solid #1c1c1c;
          &:hover {
            transform: scale(1.1);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }
        .activeColor {
          border: 4px solid #004e41;
        }
        .productSize {
          width: 52px;
          height: 48px;
          padding: 12px 7px;
          margin-right: 16px;
          margin-bottom: 16px;
          border: none;
          background-color: transparent;
          cursor: pointer;
          color: #fff;
          border: 1px solid #fff;
          border-radius: 4px;
          transition: all 0.3s ease;
          &:hover {
            transform: scale(1.1);
          }
        }
        .activeSize {
          color: #004e41;
          border-color: #004e41;
          font-weight: 600;
        }
      }
      .sizeDetermine {
        text-decoration: underline;
        display: inline-block;
        margin-bottom: 30px;
      }
      .productAddBox {
        width: 100%;
        display: flex;
        .productQty {
          border-radius: 4px;
          margin-right: 22px;
          width: 140px;
          height: 55px;
          border: 1px solid #c3c3c3;
          font-size: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          .productQtyPlus,
          .productQtyMinus {
            display: block;
            background-color: transparent;
            font-family: 'BlackProText';
            text-transform: uppercase;
            font-weight: 200;
            color: #fff;
            outline: none;
            border: none;
            width: 55px;
            position: relative;
            top: -4px;
            font-size: 30px;
            height: 55px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .productQtyNum {
            flex: 1;
            text-align: center;
          }
        }
        .addToCard {
          flex: 1;
          display: flex;
          background-color: #004e41;
          color: #f9fffe;
          font-size: 20px;
          text-align: center;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    .productData_descr {
      margin-top: 40px;
      padding: 16px;
      background-color: #1c1c1c;
      padding: 14px;
      text-align: left;
      color: #f9fffe;
      line-height: 21px;
    }
  }
}

@media (max-width: 992px) {
  .productWrapp {
    flex-direction: column;
    .productData_descr {
      padding: 12px;
    }
    .SliderWrap {
      margin: 20px 20px 40px;
      width: auto;
      // margin-left: 0; // setting a big photo slide width by padding left
    }
    .productData {
      max-width: 500px;

      margin: 0 auto;
      width: 100%;
      .productData_top {
        .watchYoutube {
          display: block;
          width: 100%;
          margin-left: 0;
          margin-top: 30px;
          margin-bottom: 28px;
        }
        .sizeDetermine {
          text-decoration: underline;
          display: inline-block;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.loaderWrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 12px solid #828181;
  border-top: 12px solid #004e41;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
