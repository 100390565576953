:root {
  --form-control-disabled: #959495;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(188, 188, 188);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #004E41;
  border-radius: 10px;
}
/* *{
  BORDER: 3px solid #fff;
} */
.App {

  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.layout{
  position: relative;
  /* overflow: hidden; */
}
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #004E41;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #004E41;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}


.carousel-indicators .carousel-indicator {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.carousel-indicators .carousel-indicator.active {
  opacity: 1;
}
.errorProduct{
  color: red;
  font-weight: 600;
  margin-top: 8px;
}
.registerInputBox{
  position: relative;
  .error{
    position: absolute;
    bottom: 20px;
    color: red;
    font-weight: 600;
    margin-top: 8px;
  }
}
.oldPrice{
  text-decoration: line-through;
  color: #004e41;
  font-size: 22px;
  margin-left: 14px;
}
.errorPasw{
  position: absolute;
  bottom: 20px;
  color: red;
  font-weight: 600;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
  border: 4px solid #004E41!important;
}
.countDownBox{
  font-size: 20px;
  font-weight: 400;
  display: block;
 
  color: #F9FFFE;
  span{
    margin-right: 9px;
  }
}

.timer{
  background-color: #004E41;
  padding: 1px 6px;
}

.seo-text{
  font-size: 13px;
  padding: 16px;
  max-width: 1100px;
  width: 100%;
  margin: 23px auto;
  text-align: left;
}
.convertPrice{
  opacity: 0.7;
  font-size: 16px;
  margin-left: 12px;
  letter-spacing: 1px;
}
.alignRight{
  text-align: right;
}