.contactWrapper{
    max-width: 1200px;
    width: 100%;
    padding: 64px 0;
    margin: 0 auto;
    .contactWrapper_left{
        width: 50%;
        position: relative;
        img{
            position: absolute;
            right: 80px;
            top: 134px;
            width: 110px;
            z-index: 1;
        }
        p{
            position: relative;
            font-family: 'BlackProText';
            font-size: 110px;
            line-height: 136px;
            font-weight: 200;
            letter-spacing: -2px;
            margin: 0;
            text-transform: uppercase;
            z-index: 2;
            &:nth-child(1){
               text-align: left;
            }
            &:nth-child(2){
                text-align: right;
                padding-right: 30px;

            }
            &:nth-child(3){
                text-align: left;
            }
        }
    }
    .contactWrapper_title{
        color: #004E41; 
        margin-bottom: 24px;
        font-size: 32px;
    }
    .contactWrapper_right{
        width: 50%;
        text-align: left;
        padding-left: 50px;
    }
    .contactWrapper_contacts{
        color: #C3C3C3;
        display: flex;
        .contact_item{
            margin-right: 32px;
            a{
                margin-right: 10px;
            }
            span{
                margin-right: 10px;
            }
            p{
                margin-top: 12px;
                a{ 
                    font-size: 20px;
                }
               }
        }
        
    }
    form{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .input_50{
            width: 45%;
            input{
                width: 100%;
                border: none;
                outline: none;
                background-color: transparent;
                border-bottom: 1px solid #C3C3C3;
                padding-bottom: 13px;
                color: #c3c3c3;
                font-size: 16px;
                margin-bottom: 45px;
            }
        }
        .input_100{
            width: 100%;
            input{
                width: 100%;
                border: none;
                outline: none;
                background-color: transparent;
                border-bottom: 1px solid #C3C3C3;
                padding-bottom: 13px;
                color: #c3c3c3;
                font-size: 16px;
                margin-bottom: 45px;
            }
            textarea{
                width: 100%;
                outline: none;
                background-color: transparent;
                border: 1px solid #C3C3C3;
                padding-bottom: 13px;
                color: #c3c3c3;
                font-size: 16px;
                margin-bottom: 45px;
                padding: 14px;
                height: 94px;
                font-family: 'Gotham';
                &::placeholder{
                    font-family: 'Gotham';
                    font-weight: 400;
                }
            }
        }
        .submit_wrap{
            text-align: right;
            width: 100%;
            .submitBtn{
                border: none;
                outline: none;
                background-color: transparent;
                cursor: pointer;
                &:hover{
                    opacity: 0.7;
                }
            }
        }
    }
}

@media (max-width: 992px){
    .contactWrapper{
        max-width: 1200px;
        width: 100%;
        padding: 64px 0;
        margin: 0 auto;
        .contactWrapper_left{
            width: 100%;
            img{
                right: 80px;
                top: 29px;
                width: 110px;
            }
            p{
                font-size: 48px;
                padding-left: 61px;
                line-height: 61px;
                &:nth-child(1){
                   text-align: left;
                }
                &:nth-child(2){
                    text-align: left;
                    padding-left: 130px;
    
                }
                &:nth-child(3){
                    padding-left: 39px;
                }
            }
        }
        .contactWrapper_title{
            color: #004E41; 
            margin-bottom: 24px;
            font-size: 32px;
        }
        .contactWrapper_right{
            width: 100%;
            text-align: left;
            padding-left: 20px;
            padding-right: 20px;
        }
        .contactWrapper_contacts{
            color: #C3C3C3;
            display: flex;
            flex-wrap: wrap;
            .contact_item{
                margin-right: 32px;
                a{
                    margin-right: 10px;
                }
                span{
                    margin-right: 10px;
                }
                p{
                    margin-top: 12px;
                    a{ 
                        font-size: 20px;
                    }
                   }
            }
            
        }
        form{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .input_50{
                width: 100%;
                input{
                    width: 100%;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    border-bottom: 1px solid #C3C3C3;
                    padding-bottom: 13px;
                    color: #c3c3c3;
                    font-size: 16px;
                    margin-bottom: 45px;
                    &::placeholder{
                        color: #c3c3c3;
                    }
                }
            }
            .input_100{
                width: 100%;
                input{
                    width: 100%;
                    border: none;
                    outline: none;
                    background-color: transparent;
                    border-bottom: 1px solid #C3C3C3;
                    padding-bottom: 13px;
                    color: #c3c3c3;
                    font-size: 16px;
                    margin-bottom: 45px;
                    &::placeholder{
                        color: #c3c3c3;
                    }
                }
                textarea{
                    width: 100%;
                    outline: none;
                    background-color: transparent;
                    border: 1px solid #C3C3C3;
                    padding-bottom: 13px;
                    color: #c3c3c3;
                    font-size: 16px;
                    margin-bottom: 45px;
                    padding: 14px;
                    height: 94px;
                    font-family: 'Gotham';
                    &::placeholder{
                        font-family: 'Gotham';
                        font-weight: 400;
                    }
                    &::placeholder{
                        color: #c3c3c3;
                    }
                }
            }
            .submit_wrap{
                text-align: right;
                width: 100%;
                .submitBtn{
                    border: none;
                    outline: none;
                    background-color: transparent;
                    cursor: pointer;
                    &:hover{
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}