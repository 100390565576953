.checkout{
    display: flex;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    .checkout_mobTitle{
      display: none;
    }
    .left{
        max-width: 533px;
        width: 100%;
        text-align: left;
        .deliveryImg{
          margin-bottom: 32px;
        }
        h2{
            font-size: 48px;
            font-family: "BlackProText";
            font-weight: 200;
            text-transform: uppercase;
            color: #F9FFFE;
            margin-bottom: 24px;
        }
        p{
            color: #c3c3c3;
            line-height: 24px;
        }
        .fomrBlock_title{
            color: #004E41;
            font-size: 32px;
            line-height: 120%; /* 38.4px */
            letter-spacing: -0.64px;
            margin-bottom: 24px;
            span{
                color: #fff;
                font-family: "BlackProText";
                font-weight: 200;
                margin-right: 23px;
                width: 50px;
                padding-top: 6px;
                text-align: center;
                position: relative;
                display: inline-block;
                &:after{
                    content: url(../../../public/img/num.svg);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
        .paymentErrorBox{
              top: 40px;
              color: red;
              font-weight: 600;
        }
        .fomrBlock_box{
            width: 100%;
            display: flex;
            margin-bottom: 32px;
            color: #c3c3c3;
            position: relative;
            .error{
              position: absolute;
              top: 40px;
              color: red;
              font-weight: 600;
            }
            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                
                background-color: #004E41;
                margin: 0 12px 0 0;
                color: #c3c3c3;
                width: 1.15em;
                height: 1.15em;
                border: 0.15em solid #004E41;
                border-radius: 0.15em;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
                cursor: pointer;
              }
              input[type="checkbox"]::before {
                content: "";
                width: 0.65em;
                height: 0.65em;
                margin-top: 8px;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em #c3c3c3;
                transform-origin: bottom left;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
              }
              input[type="checkbox"]:checked::before {
                transform: scale(1);
              }
              label{
                cursor: pointer;
              }
              select{
                cursor: pointer;
                color: #c3c3c3;
                option{
                    color: #c3c3c3;
                }
              }
        }
        .errorRelative{
          width: 100%;
          position: relative;
          color: red;
          font-size: 14px;
          font-weight: 600;
          top: -16px;
          
        }
        .fomrBlock{
            margin-top: 64px;
           display: flex;
           justify-content: space-between;
           flex-wrap: wrap; 
           .fomrBlock_title{
            width: 100%;
           }
           .inputContainer{
            width: 45%;
            position: relative;
            .error{
              position: absolute;
              color: red;
              font-size: 14px;
              font-weight: 600;
              top: 40px;
            }
            
           }
           input, select{
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            border-bottom: 1px solid #F9FFFE;
            color: #c3c3c3;
            font-size: 16px;
            padding-bottom: 13px;
            margin-bottom: 40px;
            option{
                color: #c3c3c3;
            }
           }

           .radio {
            white-space: nowrap;
            font-size: 16px;
            display: block;
            width: 45%;
            position: relative;
            padding-left: 35px;
            margin-bottom: 42px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
          }
          
          /* Hide the browser's default radio button */
          .radio input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            color: #c3c3c3;
          }
          
          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: transparent;
            border: 1px solid #004E41; 
            border-radius: 50%;
            padding-left: 20px;
            text-indent: 20px;
            cursor: pointer;
            color: #c3c3c3;
            font-size: 16px;
            padding-top: 4px;
          }
          
          /* On mouse-over, add a grey background color */
          .radio:hover input ~ .checkmark {
            background-color: #c3c3c3;
          }
          
          /* When the radio button is checked, add a blue background */
          .radio input:checked ~ .checkmark {
            background-color: transparent;
          }
          
          /* Create the indicator (the dot/circle - hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }
          
          /* Show the indicator (dot/circle) when checked */
          .radio input:checked ~ .checkmark:after {
            display: block;
          }
          
          /* Style the indicator (dot/circle) */
          .radio .checkmark:after {
               top: 4px;
              left: 4px;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: #004E41;
          }

          textarea{
            margin-top: 64px;
            height: 120px;
            background-color: transparent;
            color: #c3c3c3;
            width: 100%;
            padding: 13px 20px;
            font-size: 16px;
            margin-bottom: 24px;
            &::placeholder{
                color: #c3c3c3; 
                font-family: "Gotham",
            }
          }
        }
    }
    .right{
        position: relative;
    }
    .orderSubmit{
        background-color: #004E41;
        color: #F9FFFE;
        font-size: 20px;
        padding: 16px;
        width: 100%;
        border-radius: 4px;
        outline: none;
        border: none;
        cursor: pointer;
    }
}

@media(max-width: 1100px){
  .checkout{
    flex-wrap: wrap;
    justify-content: center;
    .left{
      margin-bottom: 40px;
    }
  }
}
@media(max-width: 567px){
  .checkout_mobTitle{
    display: block;
    h2{
      margin-top: 33px;
      font-size: 32px;
      font-family: "BlackProText";
      font-weight: 200;
      text-transform: uppercase;
      color: #F9FFFE;
      margin-bottom: 4px;
      text-align: left;
      padding: 0 20px;
  }
  p{
      color: #c3c3c3;
      line-height: 24px;
      margin-bottom: 32px;
      text-align: left;
      padding: 0 20px; 
  }
  }
  .checkout{
    flex-wrap: wrap;
    justify-content: center;
    .left{
      order: 2;
      margin-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
      h2,p{
        display: none;
      }
      .fomrBlock{
        margin-top: 64px;
       display: flex;
       justify-content: space-between;
       flex-wrap: wrap; 
       .fomrBlock_title{
        width: 100%;
        span{
          font-size: 24px;
          margin-right: 10px;
        }
       }
      
       input, select{
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        border-bottom: 1px solid #F9FFFE;
        color: #c3c3c3;
        font-size: 16px;
        padding-bottom: 13px;
        margin-bottom: 40px;
        option{
            color: #c3c3c3;
        }
       }
      }
    }
    .right{
      order: 1;
      input{
        width: 100%;
      }
    }
  }
}