$accent: #0a5447;
.search{

    position: relative;
    width: 280px;
    border-radius: 24px;
    border: 1px solid $accent;
    padding: 10px 20px;
    
    .searchList{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-height: 110px;
        height: auto;
        border-radius: 14px;
        background-color: transparent;
        overflow-y: scroll;
        z-index: 100;
        ul{
            list-style-type: none;
            margin: 0;
            padding: 0;
            border-radius: 14px;
            width: 100%;
            height: 100%;
            
        }
        li{
            width: 100%;
            background-color: #0a5447;
            padding: 3px;
            text-align: left;
            &:hover{
                background-color: #0b715e;
                cursor: pointer;
            }
            a{
                
                color: #b9b9b9;
                
            }
            
        }
    }
    .searchIco{
        position: absolute;
        left: 20px;
        top: 10px;
        width: 20px;
    }
    .closeSearch{
        position: absolute;
        top: 10px;
        right: 6px;
        width: 20px;
        cursor: pointer;
        opacity: 0.8;
    }
    input{
        background-color: transparent;
        color: #c3c3c3;
        border: none;
        outline: none;        
        padding: 0;
        font-size: 16px;
        width: 100%;
        padding-left: 28px;
    }
}
.mobSearchOn{
    display: none;
}

@media(max-width:992px){
    .search{
        display: none;
        .searchIco{
            display: none;
        }
    }
    .mobSearchOn{
        display: block;
    }
}