.orderItem {
    display: flex;
    margin-bottom: 24px;
    .img{
        width: 116px;
        height: 116px;
        object-fit: cover;
        margin-right: 24px;
        border-radius: 4px;

    }
    .center{
        flex: 1;
        .title{
            font-size: 20px;
            line-height: 114%; /* 22.8px */
            letter-spacing: -0.4px; 
            margin-bottom: 12px;
        }
        .sku{
            color: #828282;
            margin-bottom: 12px;
        }
        .bottom{
            .item{
                display: inline-block;
                margin-right: 40px;
                span{
                    display: block;
                    font-size: 16px;
                    color: #C3C3C3;
                    margin-bottom: 8px;
                    &:last-child{
                        font-size: 18px;
                        color: #F9FFFE;
                        letter-spacing: -0.36px;
                    }
                }
            }
        }
        
    }
    .price{
        font-size: 18px;
        color: #F9FFFE;
        align-self: center;
    }
}
@media(max-width: 992px){
    .orderItem{
        width: calc(100% - 20px);
    }
}