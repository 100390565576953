.ProductCard{
    max-width: 282px;
    display: block;
    width: 100%;
    margin-bottom: 40px;
    img{
        width: 100%;
        height: 160px;
        object-fit: cover;
        margin-bottom: 8px;
    }
    &_title{
        min-height: 40px;
    }
    &_title, &_price{
        font-size: 18px;
        color: #F9FFFE;
        letter-spacing: -0.36px;
        margin-bottom: 12px;
    }
    &_link{
        margin-top: 8px;
        display: block;
        border: 1px solid #004E41;
        padding: 10px 16px;
        border-radius: 4px;
        text-align: center;
    }
}
.isCentered{
    margin: 0 auto;
}


